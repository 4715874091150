import { useEffect } from 'react';
import clsx from 'clsx';
import { ClientOnly } from 'remix-utils/client-only';
import { ExternalScripts } from 'remix-utils/external-scripts';

import type { DataFunctionArgs, LinksFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError } from
'@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';

import { getUser } from './models/session.server';
import { posthog, PostHogInit } from './services/posthog.client';
import { getClientEnv } from './utils';

import tailwindStylesheetUrl from './styles/tailwind.css';
import rdtStylesheet from 'remix-development-tools/index.css';

export const links: LinksFunction = () => {
  return [
  { rel: 'stylesheet', href: tailwindStylesheetUrl },
  { rel: 'icon', type: 'image/x-icon', href: '/favicon.svg' },
  ...(process.env.NODE_ENV === 'development' ?
  [{ rel: 'stylesheet', href: rdtStylesheet }] :
  [])];

};

export async function loader({ request }: DataFunctionArgs) {
  return json({
    user: await getUser(request),
    ENV: getClientEnv()
  });
}

let AppExport = App;
// This imports the dev tools only if you're in development
if (process.env.NODE_ENV === 'development') {
  const { withDevTools } = require('remix-development-tools');
  AppExport = withDevTools(AppExport, { wsPort: 3003 });
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default withSentry(AppExport);
function App() {
  const { ENV } = useLoaderData<typeof loader>();
  const matches = useMatches();
  const location = useLocation();

  const excludeBgColour = matches.some(({ id }) =>
  ['routes/hub/$site'].includes(id)
  );

  const excludeBounce = matches.some(({ id }) =>
  ['routes/remind/snips/$snipId'].includes(id)
  );

  const bg = excludeBounce || excludeBgColour ? '' : 'bg-brand-off-white';

  const excludeScrollRestoration = matches.some(({ id }) =>
  ['routes/snips/$snipId', 'routes/forms/$formId'].includes(id)
  );

  useEffect(() => {
    if (ENV.APP_ENV === 'development') return;
    posthog.capture('$pageview');
  }, [location.pathname, location.search]);

  return (
    <html
      lang="en"
      className={clsx('h-full overflow-x-hidden scroll-smooth antialiased', bg)}>

      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <Outlet />
        {/* Exclude scroll restoration issue in iFrames */}
        {excludeScrollRestoration ? null : <ScrollRestoration />}
        <ClientOnly>{() => <PostHogInit env={ENV} />}</ClientOnly>
        <ExternalScripts />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}